import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdHelp } from "react-icons/md";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

import styles from "@styles/components/forms/vehicle-validation-sales-form.module.scss";

import { UserContext } from "@contexts/UserContext";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";
import GenericModal from "@components/GenericModal";
import Form from "@components/Form/Form";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import RouteLeavingGuard from "@components/RouteLeavingGuard/RouteLeavingGuard";
import useEffectAfterFirstRender from "@hooks/useEffectAfterFirstRender";
import {
	mapVehicleValidationSalesFromForm,
	mapVehicleValidationSalesToForm
} from "@mappers/forms/vehicleValidationSalesFormMapper";
import { vehicleValidationSalesFormSchema } from "@schema/forms/vehicleValidationSalesFormSchema";
import DocumentationType from "@constants/documentationType";
import { putSales } from "@services/Vehicle/vehicle.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import useHttpLoadingState from "@hooks/useHttpLoadingState";

const VehicleValidationSalesForm = ({
	type,
	vehicle,
	flexicarVehicleId,
	getVehicle,
	ActionButtons
}) => {
	const { t } = useTranslation("forms/vehicleValidationSalesForm");
	const formRef = useRef(null);
	const { rol, userType } = useContext(UserContext);

	const allowedEditPrice = IS_SUPER_ADMIN(userType) || rol.includes(RolType.TERRITORIAL);

	const [isDirty, setIsDirty] = useState(false);
	const [isSubmitting, handleSubmitState] = useHttpLoadingState();

	const [hasPriceChanged, setHasPriceChanged] = useState(false);
	const [watchedOffer, setWatchedOffer] = useState(vehicle.priceOffer ? true : false);
	const [watchedBuyAndSell, setWatchedBuyAndSell] = useState(vehicle.buyAndSell);
	const [watchedSalesManagement, setWatchedSalesManagement] = useState(
		!vehicle.owned || vehicle.tipo_contrato === DocumentationType.GESTION_VENTA
	);

	const saveSales = async (formData) => {
		try {
			const payload = mapVehicleValidationSalesFromForm(formData);
			await putSales(vehicle.id, payload, type);
			formRef.current.reset(formData);
			await getVehicle();
			successToast(t("saveOk"));
		} catch (err) {
			errorToast(t("saveError"));
		}
	};

	const onSubmit = async (formData) => {
		try {
			if (
				type === "stock" &&
				rol.some((rol) => [RolType.GENERAL_ADMIN, RolType.TERRITORIAL].includes(rol))
			) {
				const contracts = await instance.post(API.GET_DOCUMENTACION_FIRMAS, {
					vehicle_id: flexicarVehicleId
				});
				if (contracts.data?.length >= 0) {
					setHasPriceChanged(true);
					return;
				}
			}
			await saveSales(formData);
		} catch (err) {
			errorToast(err?.response?.data?.message || t("getDocumentationError"));
		}
	};

	useEffect(() => {
		if (formRef.current) {
			const subscription = formRef.current.watch((value, { name }) => {
				if (name === "offer") setWatchedOffer(value.offer);
				if (name === "buyAndSell") setWatchedBuyAndSell(value.buyAndSell);
				if (name === "salesManagement") setWatchedSalesManagement(value.salesManagement);
			});

			return () => subscription.unsubscribe();
		}
	}, []);

	useEffectAfterFirstRender(() => {
		if (!watchedOffer) formRef.current.setValue("priceOffer", null);
	}, [watchedOffer]);

	useEffectAfterFirstRender(() => {
		if (type === "stock") formRef.current.setValue("priceDealerSelling", vehicle.priceCash);
	}, [type, watchedBuyAndSell]);

	return (
		<>
			<Form
				ref={formRef}
				onSubmit={handleSubmitState(onSubmit)}
				className={styles.vehicle_validation_sales_form}
				defaultValues={mapVehicleValidationSalesToForm(vehicle)}
				schema={(translate) => vehicleValidationSalesFormSchema(translate, type)}
				onFormStateChange={(formState) => setIsDirty(formState.isDirty)}
				i18nErrorsFile="forms/errors"
			>
				<RouteLeavingGuard when={isDirty} />

				<ActionButtons
					isDirty={isDirty}
					isDoingRequest={isSubmitting}
				/>

				<div className={styles.vehicle_validation_sales_form__wrapper}>
					{/* Particulares */}
					<div className={styles.vehicle_validation_sales_form__particular}>
						<h4>{t("particular")}</h4>
						<FormFormattedNumberInput
							name="priceCash"
							label={t("priceCash")}
							placeholder={t("priceCash")}
							decimalScale={0}
							disabled={!allowedEditPrice}
						/>
						<FormFormattedNumberInput
							name="priceFinanced"
							label={
								<>
									<span>
										{t("priceFinanced")}
										{watchedOffer && (
											<small
												className={styles.vehicle_validation_sales_form__particular__offer__text}
											>
												{t("priceFinanceOfferText")}
											</small>
										)}
									</span>
								</>
							}
							placeholder={t("priceFinanced")}
							decimalScale={0}
							disabled={!allowedEditPrice}
						/>
						<FormCheckBox
							name="offer"
							label={t("offer")}
							disabled={!allowedEditPrice}
						/>
						{watchedOffer && (
							<FormFormattedNumberInput
								name="priceOffer"
								decimalScale={0}
								label={t("priceOffer")}
							/>
						)}
					</div>

					{/* Financiación */}
					<div className={styles.vehicle_validation_sales_form__financing}>
						<h4>{t("financing")}</h4>
						<FormFormattedNumberInput
							name="minimumInterestRate"
							label={t("minimumInterestRate")}
							placeholder={t("minimumInterestRate")}
							suffix=""
							disabled
						/>
						<FormFormattedNumberInput
							name="minimumMonths"
							label={t("minimumMonths")}
							placeholder={t("minimumMonths")}
							suffix=""
							disabled
						/>
						<FormFormattedNumberInput
							name="maximumInitialContribution"
							label={t("maximumInitialContribution")}
							placeholder={t("maximumInitialContribution")}
							suffix=""
							disabled
						/>
					</div>

					{/* Suplidos */}
					<div className={styles.vehicle_validation_sales_form__supplied}>
						<h4>{t("supplied")}</h4>
						<FormFormattedNumberInput
							name="transportAmount"
							label={t("transportAmount")}
							placeholder={t("transportAmount")}
							disabled
						/>
						<FormFormattedNumberInput
							name="flexicarWarrantyAmount"
							label={t("flexicarWarrantyAmount")}
							placeholder={t("flexicarWarrantyAmount")}
							disabled
						/>
						<FormFormattedNumberInput
							name="transferAmount"
							label={t("transferAmount")}
							placeholder={t("transferAmount")}
							disabled
						/>
					</div>

					{/* Precios de Operativa */}
					<div className={styles.vehicle_validation_sales_form__operationalPrices}>
						<h4>{t("operationalPrices")}</h4>
						<FormFormattedNumberInput
							name="priceBought"
							label={t("priceBought")}
							placeholder={t("priceBought")}
							disabled
						/>
						<FormCheckBox
							name="chargeFree"
							label={t("chargeFree")}
						/>
						<FormCheckBox
							name="taxDeductible"
							label={t("taxDeductible")}
						/>
					</div>

					{/* Tipo de operación */}
					<div className={styles.vehicle_validation_sales_form__operationType}>
						<h4>{t("operationType")}</h4>
						<div className={styles.vehicle_validation_sales_form__operationType__buyAndSell}>
							<FormCheckBox
								name="buyAndSell"
								label={t("buyAndSell")}
							/>
							<MdHelp
								id="buyAndSellPopOver"
								size={18}
							/>
							<UncontrolledPopover
								placement="bottom"
								target="buyAndSellPopOver"
								trigger="hover"
							>
								<PopoverHeader>{t("buyAndSell")}</PopoverHeader>
								<PopoverBody>{t("buyAndSellText")}</PopoverBody>
							</UncontrolledPopover>
						</div>
						<FormCheckBox
							name="salesManagement"
							label={t("salesManagement")}
							disabled
						/>
					</div>

					{/* Compraventas */}
					{watchedBuyAndSell && (
						<div className={styles.vehicle_validation_sales_form__buyAndSell}>
							<h4>{t("buyAndSell")}</h4>
							<FormFormattedNumberInput
								name="priceDealerSelling"
								label={t("priceDealerSelling")}
								placeholder={t("priceDealerSelling")}
								decimalScale={0}
								disabled={!allowedEditPrice}
							/>
						</div>
					)}

					{/* Gestion de venta */}
					{watchedSalesManagement && (
						<div className={styles.vehicle_validation_sales_form__salesManagement}>
							<h4>{t("salesManagement")}</h4>
							<FormFormattedNumberInput
								name="estimatedPrice"
								label={t("estimatedPrice")}
								placeholder={t("estimatedPrice")}
								decimalScale={0}
							/>
							<FormFormattedNumberInput
								name="commission"
								label={t("commission")}
								placeholder={t("commission")}
								decimalScale={0}
							/>
							<FormFormattedNumberInput
								name="penalty"
								label={t("penalty")}
								placeholder={t("penalty")}
								decimalScale={0}
							/>
						</div>
					)}
				</div>
			</Form>

			{hasPriceChanged && (
				<GenericModal
					isOpen={hasPriceChanged}
					setIsOpen={setHasPriceChanged}
					headContent="Guardar cambios"
					bodyContent="Atención, vas a modificar el precio de un vehículo con contrato."
					footerContent={
						<>
							<button
								className="btn btn-flexicar-inverse"
								onClick={() => setHasPriceChanged(false)}
							>
								{t("modal.cancel")}
							</button>
							<button
								className="btn-flexicar-orange"
								onClick={async () => {
									await saveSales(formRef.current.getValues());
									setHasPriceChanged(false);
								}}
							>
								{t("modal.accept")}
							</button>
						</>
					}
				/>
			)}
		</>
	);
};
export default VehicleValidationSalesForm;
