import { useState, useEffect, useCallback, useReducer, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";

import Pagination from "@views/VehicleList/components/Pagination";
import styles from "./index.module.scss";
import TableStock from "@views/VehicleList/components/TableStock";
import { dispatcher } from "@redux/actions/dispatchers";
import { getDrafts } from "@services/Vehicle/vehicle.service";
import { useTranslation } from "react-i18next";

import { getActiveLocals } from "@services/Employees/getActiveLocals";

import Paginator from "@components/Paginator";
import Select from "@components/Select";
import MultiSelectChipsList from "@components/MultiSelectChipsList";
import {
	VEHICLE_VALIDATION_ACTIONS,
	vehicleValidationFiltersInitialState,
	vehicleValidationFiltersReducer
} from "@views/VehicleList/actions/vehicleValidationReducer";
import { useDebouncedValue } from "@hooks/useDebouncedValue";
import { errorToast } from "@helpers/toastFunctions";
import { vehicleValidationPhotoTypeOptions } from "@constants/selectOptions";

const Stock = ({ storedFilters, dispatchVehicleValidationFilters }) => {
	const { t, ready } = useTranslation("vehicleValidationList");
	const history = useHistory();

	const photoTypeOptions = useMemo(() => vehicleValidationPhotoTypeOptions(t), [t]);

	const [cars, setCars] = useState({ results: null, count: 0 });

	const [filterState, filterDispatch] = useReducer(
		vehicleValidationFiltersReducer,
		storedFilters || vehicleValidationFiltersInitialState
	);
	const plateFilterDebounce = useDebouncedValue(filterState.plate, 500);

	const getUserActiveLocals = async () => {
		try {
			const responseLocalSelectOptions = await getActiveLocals();
			return responseLocalSelectOptions.map((x) => ({
				value: x.id,
				label: x.name
			}));
		} catch (e) {
			errorToast();
			return [];
		}
	};

	const searchCars = useCallback(async () => {
		if (!filterState.isUpdating) {
			const dataCall = {
				page: filterState.page,
				size: filterState.pageSize,
				locals: filterState.locales,
				hasImages: filterState.hasImages,
				order: "date",
				orderDir: "DESC",
				buyAndSell: filterState.buyAndSell
			};

			if (plateFilterDebounce === "" || plateFilterDebounce.length >= 6) {
				if (plateFilterDebounce !== "") dataCall.plate = plateFilterDebounce;

				try {
					const response = await getDrafts(dataCall);
					const { results, total } = response;
					setCars({ results, count: total });
				} catch (e) {
					errorToast();
				}
			}
		} else if (filterState.isUpdating && plateFilterDebounce === "") {
			filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.COMPLETE_UPDATE });
		}
	}, [
		filterState.isUpdating,
		filterState.page,
		filterState.pageSize,
		plateFilterDebounce,
		filterState.locales,
		filterState.hasImages,
		filterState.buyAndSell
	]);

	const handleChange = (event) =>
		event.target.type === "checkbox"
			? filterDispatch({
					type: VEHICLE_VALIDATION_ACTIONS.SET_BUY_AND_SELL,
					payload: event.target.checked
			  })
			: filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.SET_PLATE, payload: event.target.value });

	const loadVehiclePage = (id) => {
		dispatchVehicleValidationFilters(filterState);
		history.push(`/main/validacion-vehiculo/${id}?tab=datos`);
	};

	const removeValue = (value, index) => {
		filterDispatch({
			type: VEHICLE_VALIDATION_ACTIONS.SET_LOCALES,
			payload: [...filterState.locales].filter((x) => x.label !== value)
		});
	};

	useEffect(() => {
		document.title = "Flexicar | Validación de vehiculo";
		searchCars();
	}, [searchCars]);
	
	useEffect(() => {
		if (ready) {
			filterDispatch({
				type: VEHICLE_VALIDATION_ACTIONS.SET_HAS_IMAGES,
				payload: storedFilters ? storedFilters.hasImages : photoTypeOptions[0].value
			});
		}
	}, [ready, storedFilters, photoTypeOptions]);

	return (
		<div className="App">
			<Container
				className="themed-container mt-4"
				fluid={true}
			>
				<Row className="m-0">
					<Col className="col-12 col-md-12">
						<div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
							<span className="tittle ml-4">{t("vehiclesValidation")}</span>
						</div>
					</Col>
					<Col className="col-12">
						<Row className="mb-1 float-md-right">
							<div className="col-12 col-md-8 p-0 ml-md-0 ml-3">
								<div className="d-flex flex-wrap">
									<div className="col-12 col-md-3 mb-2 pl-md-3 px-0">
										<label className="m-0">{t("plate")}</label>
										<input
											style={{ height: "42px" }}
											autoComplete="off"
											placeholder={t("search")}
											className="form-control ds-input"
											type="text"
											value={filterState.plate}
											onChange={handleChange}
										/>
									</div>
									<div className="col-12 col-md-3 mb-2 pl-md-3 px-0">
										<label className="m-0">{t("local")}</label>
										<Select
											isMulti
											placeholder={t("select")}
											loadOptions={getUserActiveLocals}
											value={filterState.locales}
											onChange={(values) =>
												filterDispatch({
													type: VEHICLE_VALIDATION_ACTIONS.SET_LOCALES,
													payload: values
												})
											}
										/>
									</div>
									<div className="col-12 col-md-3 mb-2 pl-md-3 px-0">
										<label className="m-0">{t("photo")}</label>
										<Select
											placeholder={t("select")}
											options={photoTypeOptions}
											value={photoTypeOptions.find((x) => x.value === filterState.hasImages)}
											onChange={(opt) =>
												filterDispatch({
													type: VEHICLE_VALIDATION_ACTIONS.SET_HAS_IMAGES,
													payload: opt.value
												})
											}
										/>
									</div>
									<div className="col-12 col-md-3 d-flex justify-content-center checkboxCustom">
										<input
											type="checkbox"
											className="mt-3"
											checked={filterState.buyAndSell}
											onChange={handleChange}
										/>
										<label className="m-0 ml-2 mt-3 align-self-center">
											{t("professionalsBuySale")}
										</label>
									</div>

									<MultiSelectChipsList
										dropdownSelected={filterState.locales.map((x) => x.label)}
										deleteDropdownSelected={removeValue}
									/>
								</div>
							</div>
							<Col
								md="2"
								xs="6"
								className="text-right mt-4"
							>
								<Pagination
									changePagination={(_, newSize) =>
										filterDispatch({
											type: VEHICLE_VALIDATION_ACTIONS.SET_PAGE_SIZE,
											payload: newSize
										})
									}
									showNumb={filterState.pageSize}
								/>
							</Col>
							<Col
								md="2"
								xs="6"
								className="text-right mt-4"
							>
								<span className="text-success">{t("vehiclesValidation")}: </span>
								<span className="text-primary">{cars.count}</span>
							</Col>
						</Row>

						{cars.results?.length > 0 ? (
							<>
								<TableStock
									cars={cars.results}
									loadVehiclePage={loadVehiclePage}
									filterDispatch={filterDispatch}
								/>

								<div className="flex justify-content-center pt-2 pb-4">
									<Paginator
										pageSize={filterState.pageSize}
										totalCount={cars.count}
										currentPage={filterState.page}
										onPageChange={(page) =>
											filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.SET_PAGE, payload: page })
										}
									/>
								</div>
							</>
						) : (
							<div className="d-flex justify-content-center pt-5 w-100">
								{cars.results === null && (
									<>
										<Spinner
											type="grow"
											className="flexicarColor"
										/>
										<p className="flexicarColor"> {t("loading")}</p>
									</>
								)}

								{cars.results?.length === 0 && (
									<p className="flexicarColor">No se encontraron resultados</p>
								)}
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (store) => ({
	storedFilters: store.filters.vehicleValidation
});

const mapDispatch = dispatcher(["vehicleValidationFilters"]);

export default connect(mapStateToProps, mapDispatch)(Stock);
